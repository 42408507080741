import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Toast from "cogo-toast";
import Widget from "../../../components/widget";
import {IconTabs} from "../../../components/tabs";
import InputField from "../../../FinalForm/InputField";
import {Form} from "react-final-form";
import {Button} from "../../../components/My/Button";
import ApiTariffSelectField from "../../../FinalForm/api/ApiTariffSelectField";
import DateTimeField from "../../../FinalForm/DateTimeField";
import {apiGetTicket, apiPostCreateTicket, apiPutUpdateTicket} from "./Actions";
import moment from "moment";
import {FiInfo, FiSave} from "react-icons/fi";
import SelectField from "../../../FinalForm/SelectField";
import Header from "../../../components/My/Header";

const TicketForm = () => {
    let [saving, setSaving] = useState(false);
    let [loading, setLoading] = useState(false);
    let [data, setData] = useState(null);

    let user = useSelector((state) => state.user)
    const navigate = useNavigate();
    const {id} = useParams();
    let submit;

    useEffect(() => {
        id && getData()

    }, []);

    const getData = () => {
        setLoading(true)
        apiGetTicket(user.activeAreal.id, id, (data) => {
            data.tariffId = data.tariff.id
            setData(data);
            setLoading(false);
        }, (err) => {
            setLoading(false);
        })


    }

    const onSubmit = (values) => {
        setSaving(true);

        let ticketData = {
            "bc": values.bc,
            "dateTo": convertDate(values.dateTo),
            "dateFrom": convertDate(values.dateFrom),
            "entrance": values.entrance,
            "tariffId": values.tariffId,
            "type": values.type,
            "eventId": values.eventId,
            "blocked": values.blocked,
            "name": values.name,
            "note": values.note,
            "ticketType": values.ticketType,
        }

        if (id) {
            apiPutUpdateTicket(user.activeAreal.id, id, ticketData, (data) => {
                setSaving(false);
                getData()
                Toast.success("Úspěšně upraveno.");
            }, (err) => {
                Toast.warn("Při ukládání došlo k chybě.");
                setSaving(false);
            })

        } else {
            let dataToPost = {
                "eventId": values.eventId,
                "tickets": [ticketData]
            }
            apiPostCreateTicket(user.activeAreal.id, dataToPost, (data) => {
                console.log(data)
                setSaving(false);
                navigate("/ticket");
                Toast.success("Úspěšně uloženo.");
            }, (err) => {
                setSaving(false);
                Toast.warn("Při ukládání došlo k chybě.");
            })
        }
    }

    const convertDate = (date) => {
        if (date) {
            let dateInString = date.replace('T', " ");
            dateInString += ":00";
            return dateInString;
        } else {
            return null;
        }
    }

    const _renderBody = (values) => {
        if (loading) return <div>Loading...</div>;
        return (
            <Widget>
                <div className={"flex flex-wrap"}>
                    <span className={"w-full font-bold text-xl"}>Hlavní údaje</span>
                    <div className={"w-1/2"}>
                        <InputField label={"Barcode - číslo vstupenky"} name={"bc"} isMandatory={true}
                                    inputType={"text"}/>
                    </div>
                    <div className={"w-1/2"}>
                        <InputField label={"Počet vstupů"} name={"entrance"} isMandatory={true} inputType={"number"}
                                    min={0}/>
                    </div>
                    <div className={"w-1/2"}>
                        <DateTimeField name={"dateFrom"} isMandatory={true} valueToCompare={values.dateTo}
                                       validateFun={(date1, date2) => {
                                           if (moment(date1).isAfter(moment(date2))) {
                                               return "Datum od nemůže být po datu do"
                                           } else {
                                               return "";
                                           }
                                       }} label={"Platná od"} type={"from"} placeholder={"Datum od"}
                                       showTimeSelect={true}/>
                    </div>
                    <div className={"w-1/2"}>
                        <DateTimeField name={"dateTo"} isMandatory={true} valueToCompare={values.dateFrom}
                                       validateFun={(date1, date2) => {
                                           if (moment(date2).isAfter(moment(date1))) {
                                               return "Datum od nemůže být po datu do"
                                           } else {
                                               return "";
                                           }
                                       }} label={"Platná do"} type={"to"} placeholder={"Datum do"}
                                       showTimeSelect={true}/>
                    </div>
                    <div className={"w-full"}>
                        <ApiTariffSelectField isMulti={false} isMandatory={true} name={"tariffId"}/>
                    </div>
                    <div className={"w-1/2"}>
                        <SelectField name={"ticketType"} options={[
                            {label: "Servisní karta/zaměstnanci", value: 0},
                            {label: "Běžná vstupenka", value: 1},
                            {label: "Karta s kreditem", value: 2}
                        ]} label={"Typ vstupenky"} isMandatory={true}/>
                    </div>
                    <div className={"w-1/2"}>
                        {id && <SelectField name={"blocked"} options={[
                            {label: "ANO", value: 1}, {label: "NE", value: 0}
                        ]} label={"Vstupenka blokována"} isMandatory={false} placeholder={"Zablokovat vstupenku?"}/>}
                    </div>
                    <span className={"w-full font-bold text-xl mt-2 mb-2"}>Informace o držiteli</span>
                    <div className={"w-1/2"}>
                        <InputField label={"Jméno"} name={"name"} isMandatory={false}/>
                    </div>
                    <div className={"w-1/2"}>
                        <InputField label={"Poznámka"} name={"note"} isMandatory={false}/>
                    </div>
                    <span className={"w-full font-bold text-xl mt-2 mb-2"}>Další možnosti</span>
                    <div className={"w-1/3"}>
                        <InputField label={"ID události"} name={"eventId"} isMandatory={true} inputType={"number"} min={0}/>
                    </div>
                    {user.activeAreal.id === 31 &&
                        <div className={"w-1/3"}>
                            <SelectField name={"type"} options={[
                                {label: "Děti", value: 1},
                                {label: "ZTP", value: 2}
                            ]} label={"Typ vstupenky"} isMandatory={false}/>
                        </div>}


                </div>
                <Button text={"Uložit"} onClick={submit} icon={<FiSave/>}
                        loading={saving}/>
            </Widget>
        )
    }

    return (

        <Form onSubmit={onSubmit}
              initialValues={data ? data : {eventId: 0}}
              render={({handleSubmit, errors, values}) => {
                  submit = handleSubmit
                  return (
                      <Header data={[{
                          text: "Vstupenky",
                          isMain: false,
                          link: "/ticket"
                      }, {text: id ? "Úprava vstupenky" : "Nová vstupenka", isMain: true}]}>
                          {_renderBody(values)}
                      </Header>
                  )
              }}/>
    )
}
export default TicketForm;
